import { useEffect, useState } from 'react';
import { Client as StompClient } from '@stomp/stompjs';
import SockJS from 'sockjs-client'; 
import { useSelector } from 'react-redux';

const useWebSocket = (onMessageReceived) => {

  const userInfo = useSelector((state) => state.session)
  const [connected, setConnected] = useState(false);

  const showGreeting = (message) => {
    // console.log("Greeting from:", message);
    // Add any additional logic you need here
  };

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${userInfo?.token}` // Add your token here
    };

    const socket = new SockJS(`https://crmtest.lipachat.com/ws?username=${userInfo?.user?.username}`);
    const stompClient = new StompClient({
      webSocketFactory: () => socket,
      connectHeaders: headers,
      debug: (str) => console.log(str)
    });

    stompClient.onConnect = (frame) => {
      setConnected(true);
      // console.log('Connected: ' + frame);
      stompClient.subscribe('/topic/messages', (message) => {
        // console.log("Received new message " + message);
        showGreeting(JSON.parse(message.body).from);
      });
      stompClient.subscribe('/user/queue/messages', (message) => {
        // console.log("Received new queue message " + message);
        // showGreeting(JSON.parse(message.body).from);
        if (onMessageReceived) {
          onMessageReceived(); // Call the callback function when a new queue message is received
        }
      });
    };

    stompClient.onWebSocketError = (error) => {
      // console.error('Error with websocket', error);
    };

    stompClient.onStompError = (frame) => {
      // console.error('Broker reported error: ' + frame.headers['message']);
      // console.error('Additional details: ' + frame.body);
    };

    const connect = () => {
      stompClient.activate();
    };

    const disconnect = () => {
      stompClient.deactivate();
      setConnected(false);
    };

    connect();

    return () => {
      disconnect();
    };
  }, [onMessageReceived, userInfo?.token, userInfo?.user?.username]);

  return { connected };
};

export default useWebSocket;
